import { Query } from "@graphql-types";
import { ErrorMsg } from "@shared";
import { graphql, PageProps } from "gatsby";
import React from "react";
import ContactTemplate from "../templates/contactTemplate";

interface Props extends PageProps {
  data: Query;
}

const BookATour = ({ data }: Props) => {
  if (data.sanityBookatour == null)
    return <ErrorMsg data={data.sanityContactPage} msg="Error fetching data for page" />;

  const {
    sanityBookatour: { title, blockContent },
  } = data;

  return (
    <div>
      <ContactTemplate data={data} title={title} hero={blockContent} formType="bookatour" />
    </div>
  );
};

export default BookATour;

export const query = graphql`
  query BookATourPageQuery {
    allSanityVillage(sort: { fields: title, order: ASC }, filter: { canBookATour: { eq: true } }) {
      nodes {
        title
        canBookATour
        villageCode
        dynamicsVillageCode
        region {
          ...sanityRegion
        }
      }
    }
    sanityHeader {
      ...sanityHeader
    }
    sanityBookatour {
      seo {
        ...sanitySeo
      }
      pageColour {
        ...sanityColorPicker
      }
      ctaTitle
      contactCtas {
        ...sanityContactCta
      }
      enquiryContacts {
        ...sanityContactCta
      }
      _type
      title
      blockContent {
        _rawBlockContent(resolveReferences: { maxDepth: 2 })
      }
    }
    allSanityRegion(sort: { order: ASC, fields: order }) {
      nodes {
        ...sanityRegion
      }
    }
  }
`;
